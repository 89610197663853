
import { Component, Vue } from "vue-property-decorator";
import { EmployerNoticeService } from "src/services";
import { Toast, Dialog } from "vant";
import tabbar from "src/components/tabbar";

@Component({
  components: {
    tabbar,
  },
})
export default class noticeDetail extends Vue {
  /**
   * 用户id
   * @private
   * @returns any
   */
  private noticeDetail: any = {};

  /**
   * 用户id
   * @private
   * @returns string
   */
  private get id(): string {
    return (this.$route.query && (this.$route.query.id as string)) || "";
  }

  /**
   * 获取消息详情
   * @private
   * @returns void
   */
  private async getNoticeList(id: any): Promise<void> {
    try {
      let { content: result } = await EmployerNoticeService.instance.getNotice(id);
      if (result.data) {
        this.noticeDetail = result.data;
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 获取数据
   * @private
   * @returns void
   */
  private created(): void {
    if (this.id) {
      this.getNoticeList(this.id);
    }
  }

  /**
   * 跳转详情
   * @private
   * @returns void
   */
  private onDetail(id: string): void {
    this.$router.push({ name: "notice-detail", query: { id: id } });
  }
}
